import {
  FETCH_CATEGORIES_REQUEST,
  FETCH_CATEGORIES_FAILURE,
  FETCH_CATEGORIES_RESPONSE,
  CategoryState,
  CategoryActionTypes,
} from '../types/Category';

const initialState: CategoryState = {
  categories: [],
  isFetchingCategories: false,
  fetchingCategoriesFailed: false,
};

const categoriesReducer = (
  state = initialState,
  action: CategoryActionTypes,
): CategoryState => {
  switch (action.type) {
    case FETCH_CATEGORIES_REQUEST:
      return {
        ...state,
        categories: [],
        isFetchingCategories: true,
        fetchingCategoriesFailed: false,
      };
    case FETCH_CATEGORIES_RESPONSE:
      return {
        ...state,
        categories: action.payload || [],
        isFetchingCategories: false,
        fetchingCategoriesFailed: false,
      };
    case FETCH_CATEGORIES_FAILURE:
      return {
        ...state,
        categories: [],
        isFetchingCategories: false,
        fetchingCategoriesFailed: true,
      };
    default:
      return state;
  }
};

export default categoriesReducer;
