import {ThunkAction} from 'redux-thunk';
import {Action} from 'redux';
import {
    FETCH_CATEGORIES_REQUEST,
    FETCH_CATEGORIES_RESPONSE,
    FETCH_CATEGORIES_FAILURE,
    Category,
    CategoryActionTypes,
} from '../types/Category';


import {RootState} from '../store/configureStore';

import {getCategories} from '../api/Category';

export function fetchCategoriesRequest(): CategoryActionTypes {
    return {
        type: FETCH_CATEGORIES_REQUEST,
        payload: null,
    };
}

export function fetchCategoriesResponse(
    categories: Category[],
): CategoryActionTypes {
    return {
        type: FETCH_CATEGORIES_RESPONSE,
        payload: categories,
    };
}

export function fetchCategoriesFailure(): CategoryActionTypes {
    return {
        type: FETCH_CATEGORIES_FAILURE,
        payload: null,
    };
}

export const fetchCategories = ():
    ThunkAction<void, RootState, unknown, Action<string>> =>
    async (dispatch) => {
        dispatch(fetchCategoriesRequest());
        const asyncResp: any = await getCategories();
        if (asyncResp) {
            await dispatch(fetchCategoriesResponse(asyncResp));
        } else {
            await dispatch(fetchCategoriesFailure());
        }
    };
