import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {getCategories} from '../selectors/Category';
import {fetchCategories} from '../actions/Category';
import {getFieldTypes} from '../selectors/FieldType';
import {fetchFieldTypes} from '../actions/FieldType';
import {getFields} from '../selectors/Field';
import {fetchFields} from '../actions/Field';
import {getProjects} from '../selectors/Product';
import {fetchProducts} from '../actions/Product';
import View from '../components/View';
import {Category} from "../types/Category";
import {Field} from "../types/Field";
import {ProductField} from "../types/Product";
import {Accordion} from "react-bootstrap";

function Product() {
    const dispatch = useDispatch();
    // @ts-ignore
    const params = useParams();
    const categories = useSelector(getCategories);
    const fieldTypes = useSelector(getFieldTypes);
    const fields = useSelector(getFields);
    const products = useSelector(getProjects);
    useEffect(() => {
        dispatch(fetchCategories());
        dispatch(fetchFieldTypes());
        dispatch(fetchFields());
        dispatch(fetchProducts())
    }, [dispatch]);

    // @ts-ignore
    const productId = params?.projectId || '';

    const product = products?.find(p => p.id === productId);

    const renderField = (field: Field) => {
        const productField = product?.fields.find(pf => pf.id === field.id);
        return <div className="row" key={field.id}>
            <div className="col-4 text-end">
                <strong>{field?.label}:</strong>
            </div>
            <div className="col-8">
                {field?.fieldTypeId === 'image' && <img width="50%" src={productField?.value} alt={productField?.value} />}
                {field?.fieldTypeId !== 'image' && <p className="text-truncate">{productField?.value}</p>}
            </div>
        </div>
    };

    const renderCategory = (category: Category) => {
        const productFields: Field[] = [];
        product?.fields.forEach((pf: ProductField) => {
            const innerField = fields?.find(f => f.id === pf?.id);
            if (innerField) {
                productFields.push(innerField);
            }
        })

        const categoryFields = productFields?.filter(f => f?.categoryId === category.id);

        if (!categoryFields || categoryFields.length <= 0) {
            return '';
        }

        return <div key={category.id} className="mt-4 col-12">
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{category.title}</Accordion.Header>
                    <Accordion.Body>
                        <div>
                            {categoryFields.map(pf => renderField(pf))}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    };

    return (
        <View>
            <div className="page">
                <div className="container">
                    <div className="row">
                        {categories?.map(c => renderCategory(c))}
                    </div>
                </div>
            </div>
        </View>
    );
}

export default Product;
