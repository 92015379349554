import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getProducts} from '../selectors/Product';
import {fetchProducts} from '../actions/Product';
import View from '../components/View';
import {Product} from "../types/Product";
import '../styles/Dashboard.scss';
import {Button} from "react-bootstrap";
import {useHistory} from 'react-router-dom';

function Products() {
    const dispatch = useDispatch();
    const products = useSelector(getProducts);
    useEffect(() => {
        dispatch(fetchProducts())
    }, [dispatch]);

    const history = useHistory();
    const onNavigate = (productId: string) => {
        history.push(`/products/${productId}`);
    }

    const renderProduct = (p: Product) => {
        const nameField = p.fields.find(f => f.id === 'product-name');
        const primaryImageField = p.fields.find(f => f.id === 'product-image');
        const priceField = p.fields.find(f => f.id === 'product-cost');
        return (<div className="card">
            <img className="card-img-top" src={primaryImageField?.value} alt={primaryImageField?.id}/>
            <div className="card-body">
                <h5 className="card-title">{nameField?.value}</h5>
                <p className="card-text">Cost: {priceField?.value}</p>
                <Button onClick={() => onNavigate(p.id)} variant="primary">Read More</Button>
            </div>
        </div>);
    };

    return (
        <View>
            <div className="page">
                <h1 className="mb-4">Products</h1>
                <div className="container">
                    <div className="row">
                        {products?.map(p =>
                            <div key={p.id} className="col-md-4 mb-4">
                                {renderProduct(p)}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </View>

    );
}

export default Products;
