import {
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_FAILURE,
  FETCH_PRODUCTS_RESPONSE,
  ProductState,
  ProductActionTypes,
} from '../types/Product';

const initialState: ProductState = {
  products: [],
  isFetchingProducts: false,
  fetchingProductsFailed: false,
};

const productsReducer = (
  state = initialState,
  action: ProductActionTypes,
): ProductState => {
  switch (action.type) {
    case FETCH_PRODUCTS_REQUEST:
      return {
        ...state,
        products: [],
        isFetchingProducts: true,
        fetchingProductsFailed: false,
      };
    case FETCH_PRODUCTS_RESPONSE:
      return {
        ...state,
        products: action.payload || [],
        isFetchingProducts: false,
        fetchingProductsFailed: false,
      };
    case FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        products: [],
        isFetchingProducts: false,
        fetchingProductsFailed: true,
      };
    default:
      return state;
  }
};

export default productsReducer;
