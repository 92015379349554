export const FETCH_CATEGORIES_REQUEST = 'FETCH_CATEGORIES_REQUEST';
export const FETCH_CATEGORIES_RESPONSE = 'FETCH_CATEGORIES_RESPONSE';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';

export interface Category {
    id: string;
    title: string;
    description: string;
    created_at: Date;
    updated_at: Date;
}

export interface CategoryState {
    categories: Category[],
    isFetchingCategories: boolean;
    fetchingCategoriesFailed: boolean;
}

interface FetchCategoriesRequestAction {
    type: typeof FETCH_CATEGORIES_REQUEST;
    payload: null;
}

interface FetchCategoriesResponseAction {
    type: typeof FETCH_CATEGORIES_RESPONSE;
    payload: Category[];
}

interface FetchCategoriesFailureAction {
    type: typeof FETCH_CATEGORIES_FAILURE;
    payload: null;
}

export type CategoryActionTypes =
    | FetchCategoriesRequestAction
    | FetchCategoriesResponseAction
    | FetchCategoriesFailureAction;
