export const FETCH_FIELD_TYPES_REQUEST = 'FETCH_FIELD_TYPES_REQUEST';
export const FETCH_FIELD_TYPES_RESPONSE = 'FETCH_FIELD_TYPES_RESPONSE';
export const FETCH_FIELD_TYPES_FAILURE = 'FETCH_FIELD_TYPES_FAILURE';

export interface FieldType {
    id: string;
    type: string;
    prefix?: boolean;
    suffix?: boolean;
    tip?: string;
    tooltip?: string;
    default?: any;
    created_at: Date;
    updated_at: Date;
}

export interface FieldTypeState {
    fieldTypes: FieldType[],
    isFetchingFieldTypes: boolean;
    fetchingFieldTypesFailed: boolean;
}

interface FetchFieldTypesRequestAction {
    type: typeof FETCH_FIELD_TYPES_REQUEST;
    payload: null;
}

interface FetchFieldTypesResponseAction {
    type: typeof FETCH_FIELD_TYPES_RESPONSE;
    payload: FieldType[];
}

interface FetchFieldTypesFailureAction {
    type: typeof FETCH_FIELD_TYPES_FAILURE;
    payload: null;
}

export type FieldTypeActionTypes =
    | FetchFieldTypesRequestAction
    | FetchFieldTypesResponseAction
    | FetchFieldTypesFailureAction;
