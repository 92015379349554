const API_BASE_URL = 'http://enfield.novumtempus.com/api';

const API_ENDPOINTS = {
  CATEGORIES: `${API_BASE_URL}/category/categories.json`,
  PRODUCTS: `${API_BASE_URL}/product/products.json`,
  FIELD_TYPES: `${API_BASE_URL}/field-type/field-types.json`,
  FIELDS: `${API_BASE_URL}/field/general-info.json`,
  FIELDS_COMPONENTS: `${API_BASE_URL}/field/components.json`,
};


const APP_ROUTES = {
  DASHBOARD: '/',
  PRODUCTS: '/products',
  PRODUCT: '/products/:productId',
  PROJECTS: '/projects',
  PROJECT: '/projects/:projectId',
};

const CONSTANTS = {
  API_ENDPOINTS,
  APP_ROUTES,
};

export default CONSTANTS;
