import {ThunkAction} from 'redux-thunk';
import {Action} from 'redux';
import {
    FETCH_FIELD_TYPES_FAILURE,
    FETCH_FIELD_TYPES_REQUEST,
    FETCH_FIELD_TYPES_RESPONSE,
    FieldType,
    FieldTypeActionTypes
} from '../types/FieldType';

import {RootState} from '../store/configureStore';

import {getFieldTypes} from '../api/FieldType';

export function fetchFieldTypesRequest(): FieldTypeActionTypes {
    return {
        type: FETCH_FIELD_TYPES_REQUEST,
        payload: null,
    };
}

export function fetchFieldTypesResponse(
    fieldTypes: FieldType[],
): FieldTypeActionTypes {
    return {
        type: FETCH_FIELD_TYPES_RESPONSE,
        payload: fieldTypes,
    };
}

export function fetchFieldTypesFailure(): FieldTypeActionTypes {
    return {
        type: FETCH_FIELD_TYPES_FAILURE,
        payload: null,
    };
}

export const fetchFieldTypes = ():
    ThunkAction<void, RootState, unknown, Action<string>> =>
    async (dispatch) => {
        dispatch(fetchFieldTypesRequest());
        const asyncResp: any = await getFieldTypes();
        if (asyncResp) {
            await dispatch(fetchFieldTypesResponse(asyncResp));
        } else {
            await dispatch(fetchFieldTypesFailure());
        }
    };
