import {
  FETCH_FIELDS_REQUEST,
  FETCH_FIELDS_FAILURE,
  FETCH_FIELDS_RESPONSE,
  FieldState,
  FieldActionTypes,
} from '../types/Field';

const initialState: FieldState = {
  fields: [],
  isFetchingFields: false,
  fetchingFieldsFailed: false,
};

const fieldsReducer = (
  state = initialState,
  action: FieldActionTypes,
): FieldState => {
  switch (action.type) {
    case FETCH_FIELDS_REQUEST:
      return {
        ...state,
        fields: [],
        isFetchingFields: true,
        fetchingFieldsFailed: false,
      };
    case FETCH_FIELDS_RESPONSE:
      return {
        ...state,
        fields: action.payload || [],
        isFetchingFields: false,
        fetchingFieldsFailed: false,
      };
    case FETCH_FIELDS_FAILURE:
      return {
        ...state,
        fields: [],
        isFetchingFields: false,
        fetchingFieldsFailed: true,
      };
    default:
      return state;
  }
};

export default fieldsReducer;
