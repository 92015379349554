import React from 'react';
import { Switch, Route } from 'react-router-dom';
import c from '../../helpers/constants';
import Products from '../../views/Products';
import Product from '../../views/Product';
import Projects from "../../views/Projects";
import Project from "../../views/Project";
import Dashboard from "../../views/Dashboard";

const Router = () => {
  return (
        <Switch>
            <Route path={c.APP_ROUTES.PRODUCTS} exact component={Products}/>
            <Route path={c.APP_ROUTES.PRODUCT} exact component={Product}/>
            <Route path={c.APP_ROUTES.PROJECTS} exact component={Projects}/>
            <Route path={c.APP_ROUTES.PROJECT} exact component={Project}/>
            <Route path={c.APP_ROUTES.DASHBOARD} exact component={Dashboard}/>
        </Switch>
  );
};

export default Router;
