import axios from 'axios';

import c from '../helpers/constants';

export function getFields() {
  return (
    axios({
      url: c.API_ENDPOINTS.FIELDS,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getComponentFields() {
    return (
        axios({
            url: c.API_ENDPOINTS.FIELDS_COMPONENTS,
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        })
            .then((response: any) => {
                return response?.data;
            })
            .catch((error: any) => {
                return error.response?.data;
            })
    );
}
