import {
  FETCH_FIELD_TYPES_REQUEST,
  FETCH_FIELD_TYPES_FAILURE,
  FETCH_FIELD_TYPES_RESPONSE,
  FieldTypeState,
  FieldTypeActionTypes,
} from '../types/FieldType';

const initialState: FieldTypeState = {
  fieldTypes: [],
  isFetchingFieldTypes: false,
  fetchingFieldTypesFailed: false,
};

const fieldTypesReducer = (
  state = initialState,
  action: FieldTypeActionTypes,
): FieldTypeState => {
  switch (action.type) {
    case FETCH_FIELD_TYPES_REQUEST:
      return {
        ...state,
        fieldTypes: [],
        isFetchingFieldTypes: true,
        fetchingFieldTypesFailed: false,
      };
    case FETCH_FIELD_TYPES_RESPONSE:
      return {
        ...state,
        fieldTypes: action.payload || [],
        isFetchingFieldTypes: false,
        fetchingFieldTypesFailed: false,
      };
    case FETCH_FIELD_TYPES_FAILURE:
      return {
        ...state,
        fieldTypes: [],
        isFetchingFieldTypes: false,
        fetchingFieldTypesFailed: true,
      };
    default:
      return state;
  }
};

export default fieldTypesReducer;
