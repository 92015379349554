import {ThunkAction} from 'redux-thunk';
import {Action} from 'redux';
import {
    FETCH_PRODUCTS_REQUEST,
    FETCH_PRODUCTS_RESPONSE,
    FETCH_PRODUCTS_FAILURE,
    Product,
    ProductActionTypes,
} from '../types/Product';


import {RootState} from '../store/configureStore';

import {getProducts} from '../api/Product';

export function fetchProductsRequest(): ProductActionTypes {
    return {
        type: FETCH_PRODUCTS_REQUEST,
        payload: null,
    };
}

export function fetchProductsResponse(
    products: Product[],
): ProductActionTypes {
    return {
        type: FETCH_PRODUCTS_RESPONSE,
        payload: products,
    };
}

export function fetchProductsFailure(): ProductActionTypes {
    return {
        type: FETCH_PRODUCTS_FAILURE,
        payload: null,
    };
}

export const fetchProducts = ():
    ThunkAction<void, RootState, unknown, Action<string>> =>
    async (dispatch) => {
        dispatch(fetchProductsRequest());
        const asyncResp: any = await getProducts();
        if (asyncResp) {
            await dispatch(fetchProductsResponse(asyncResp));
        } else {
            await dispatch(fetchProductsFailure());
        }
    };
