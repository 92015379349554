export const FETCH_PRODUCTS_REQUEST = 'FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_RESPONSE = 'FETCH_PRODUCTS_RESPONSE';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';

export interface ProductField {
    id: string;
    value: any;
    currency?: string;
}

export interface Product {
    id: string;
    fields: ProductField[];
    created_at: Date;
    updated_at: Date;
}

export interface ProductState {
    products: Product[],
    isFetchingProducts: boolean;
    fetchingProductsFailed: boolean;
}

interface FetchProductsRequestAction {
    type: typeof FETCH_PRODUCTS_REQUEST;
    payload: null;
}

interface FetchProductsResponseAction {
    type: typeof FETCH_PRODUCTS_RESPONSE;
    payload: Product[];
}

interface FetchProductsFailureAction {
    type: typeof FETCH_PRODUCTS_FAILURE;
    payload: null;
}

export type ProductActionTypes =
    | FetchProductsRequestAction
    | FetchProductsResponseAction
    | FetchProductsFailureAction;
