import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getProducts} from '../selectors/Product';
import {fetchProducts} from '../actions/Product';
import View from '../components/View';
import {Product} from "../types/Product";
import '../styles/Dashboard.scss';
import {Button} from "react-bootstrap";
import {useHistory} from 'react-router-dom';

function Dashboard() {

    return (
        <View>
            <div className="page">
                <h1 className="mb-4">Dashboard</h1>
                <div className="container">
                    <div className="row">
                        <h3>Enfield Council</h3>
                        <p>Welcome</p>
                    </div>
                </div>
            </div>
        </View>

    );
}

export default Dashboard;
