import { RootState } from '../store/configureStore';

export const getProducts = (state: RootState) => state?.product?.products.filter(p => p.id !== 'project-id-1');

export const getProjects = (state: RootState) => state?.product?.products.filter(p => p.id === 'project-id-1');

export const isFetchingProducts = (state: RootState) =>
  state?.product?.isFetchingProducts

export const fetchingProductsFailed = (state: RootState) =>
  state?.product?.fetchingProductsFailed;
