import React from 'react';
import Router from '../Router';
import Header from "../Header";

function App() {
    return (
        <div className="App">
            <Router />
        </div>
    );
}

export default App;
