import {ThunkAction} from 'redux-thunk';
import {Action} from 'redux';
import {
    FETCH_FIELDS_REQUEST,
    FETCH_FIELDS_RESPONSE,
    FETCH_FIELDS_FAILURE,
    Field,
    FieldActionTypes,
} from '../types/Field';


import {RootState} from '../store/configureStore';

import {getComponentFields, getFields} from '../api/Field';

export function fetchFieldsRequest(): FieldActionTypes {
    return {
        type: FETCH_FIELDS_REQUEST,
        payload: null,
    };
}

export function fetchFieldsResponse(
    fields: Field[],
): FieldActionTypes {
    return {
        type: FETCH_FIELDS_RESPONSE,
        payload: fields,
    };
}

export function fetchFieldsFailure(): FieldActionTypes {
    return {
        type: FETCH_FIELDS_FAILURE,
        payload: null,
    };
}

export const fetchFields = ():
    ThunkAction<void, RootState, unknown, Action<string>> =>
    async (dispatch) => {
        dispatch(fetchFieldsRequest());
        const asyncRespOne: any = await getFields();
        const asyncRespTwo: any = await getComponentFields();
        if (asyncRespOne && asyncRespTwo) {
            await dispatch(fetchFieldsResponse([...asyncRespOne, ...asyncRespTwo]));
        } else {
            await dispatch(fetchFieldsFailure());
        }
    };
