export const FETCH_FIELDS_REQUEST = 'FETCH_FIELDS_REQUEST';
export const FETCH_FIELDS_RESPONSE = 'FETCH_FIELDS_RESPONSE';
export const FETCH_FIELDS_FAILURE = 'FETCH_FIELDS_FAILURE';

export interface Option {
    id: string;
    label: string;
}

export interface Field {
    id: string;
    label: string;
    categoryId: string;
    fieldTypeId: string;
    placeholder?: string;
    required?: boolean;
    options?: Option[];
    prefix?: boolean;
    suffix?: boolean;
    tip?: string;
    tooltip?: string;
    default?: any;
    created_at: Date;
    updated_at: Date;
}

export interface FieldState {
    fields: Field[],
    isFetchingFields: boolean;
    fetchingFieldsFailed: boolean;
}

interface FetchFieldsRequestAction {
    type: typeof FETCH_FIELDS_REQUEST;
    payload: null;
}

interface FetchFieldsResponseAction {
    type: typeof FETCH_FIELDS_RESPONSE;
    payload: Field[];
}

interface FetchFieldsFailureAction {
    type: typeof FETCH_FIELDS_FAILURE;
    payload: null;
}

export type FieldActionTypes =
    | FetchFieldsRequestAction
    | FetchFieldsResponseAction
    | FetchFieldsFailureAction;
