import Header from "../Header";
import React from "react";

const View = (props: any) => {
    return (<>
            <header>
                <Header/>
            </header>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12 mt-5">
                            {props.children}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default View;
