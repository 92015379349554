import { createStore, applyMiddleware, combineReducers, Action } from 'redux';
import thunk, { ThunkAction } from 'redux-thunk';
import categoryReducer from '../reducers/Category';
import fieldTypeReducer from '../reducers/FieldType';
import fieldReducer from '../reducers/Field';
import productsReducer from '../reducers/Product';

const rootReducer = combineReducers({
  category: categoryReducer,
  fieldType: fieldTypeReducer,
  field: fieldReducer,
  product: productsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const store = createStore(rootReducer, applyMiddleware(thunk));

export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
RootState,
unknown,
Action<string>
>;

export default store;
